import React from 'react'
import icon1 from '../../assets/icons/money-bag.png'
import icon2 from '../../assets/icons/box.png'
import icon3 from '../../assets/icons/layout.png'
import { Link } from 'react-router-dom'

function Feature() {
    return (
        <div className='sm:px-12 px-4 pt-24 flex lg:flex-row flex-col width lg:height min-w-auto mx-auto'>
            <div className='lg:w-1/2 text-left lg:text-center '>
                <h2 className='text-black lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-aeroport font-bold lg:text-left text-center'>Ya no tendrás que<br /> preocuparte por quedarte<br /> sin espacio.</h2>
            </div>
            <div className='lg:w-1/2 mt-9'>
                <Link to="savemore">
                    <div className='flex sm:flex-row flex-col gap-10'>
                        <div>
                            <img src={icon1}
                                width={53}
                                height={53} alt="money-bag" />
                        </div>
                        <div className=' border-b-2'>
                            <h2 className='font-aeroport font-bold md:text-4xl text-2xl'>Ahorra Más</h2>
                            <p className='mt-1 font-aeroport font-normal text-[#21A3AA] text-2xl mb-8 '>Solo paga por el espacio que necesitas y evita<br /> costosos cargos de mudanza. ¡Ahorra hasta un 40%!</p>
                        </div>
                    </div>
                </Link>
                <Link to="ultimate">
                    <div className='flex sm:flex-row flex-col gap-10 mt-6'>
                        <div>
                            <img src={icon2}
                                width={53}
                                height={53} alt="money-bag" />
                        </div>
                        <div className=' border-b-2'>
                            <h2 className='font-bold font-aeroport md:text-4xl text-2xl'>Conveniencia Máxima</h2>
                            <p className='mt-1 text-2xl font-aeroport text-[#21A3AA] font-normal mb-8 '> Olvídate de levantar objetos pesados y los problemas de mudanza.<br /> Te ahorramos horas de esfuerzo.</p>
                        </div>
                    </div>
                </Link>

                <Link to="flexible">
                    <div className='flex sm:flex-row flex-col gap-10 mt-6'>
                        <div>
                            <img src={icon3}
                                width={53}
                                height={53} alt="money-bag" />
                        </div>
                        <div className=' border-b-2'>
                            <h2 className='font-bold md:text-4xl font-aeroport text-2xl'>Planes Flexibles</h2>
                            <p className='mt-1 text-2xl mb-8 text-[#21A3AA] font-aeroport font-normal '>¿Necesitas más o menos espacio? ¡No hay problema!<br /> Nuestros planes se adaptan a tus necesidades.</p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Feature
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
// import esMX from "date-fns/locale/es-MX";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { app } from "../../../firebase";

const locales = {
  "en-US": enUS,
  // "es-MX": esMX,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const firestore = getFirestore(app);
const storage = getStorage(app);

function ReturnsTable(props) {
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null); // State to store selected request
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]); // State to store uploaded images
  const [itemDetails, setItemDetails] = useState({}); // State to store item details
  const [planDetails, setPlanDetails] = useState(null);
  const [date, setDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false); // Modal state
  const [rejectReason, setRejectReason] = useState(""); // Rejection reason state
  const [selectedRequestForRejection, setSelectedRequestForRejection] =
    useState(null); // Selected request for rejection

  console.log(selectedRequest);

  useEffect(() => {
    const fetchRequests = async () => {
      const requestCollection = collection(firestore, "return_requests");
      const requestSnapshot = await getDocs(
        query(requestCollection)
      );
      const requestList = requestSnapshot.docs.map((doc) => {
        const data = doc.data();
        console.log(data);
        // Convert timestamps to readable strings
        const createdAt =
          data.created_at && data.created_at.toDate
            ? data.created_at.toDate().toLocaleString()
            : data.created_at;

        const createdMonth = createdAt
          ? createdAt.toLocaleString("en-US", { month: "long" })
          : null;
        //const createdMonth = data.created_at && data.created_at.toDate ? data.created_at.toDate().toLocaleString('en-US', { month: 'long' }) : null;
        // const pickUpDateTime = data.pick_up_date_time.toDate().toLocaleString();
        const pickUpDateTime = new Date(
          data.scheduleDateTime.toMillis()
        ).toLocaleString(); // return data;
        return {
          id: doc.id,
          ...data,
          created_at: createdAt,
          created_month: createdMonth, // Store the month separately
          pick_up_date_time: pickUpDateTime,
        };
      });
      setRequests(requestList);

      var eventList = [];
      for (var request of requestList) {
        const timeStampDate = request.scheduleDateTime;
        let epochTimestamp = timeStampDate.toMillis();
        //epochTimestamp = 1621081015081
        //Now this timestamp can be used as usual javascript timestamp which is easy to manipulate.
        let date = new Date(epochTimestamp); //date = Sat May 15 2021 17:46:55 GMT+0530 (India Standard Time)
        // console.log("time__", date, date.getYear(), date.month, date.day);
        eventList.push({
          id: 0,
          title: request.additionalInfo,
          start: date, // September 29, 2024, 9:00 AM
          end: date, // September 29, 2024, 12:00 PM
        });
      }
      setEvents(eventList);
    };

    fetchRequests();
  }, []);

  const handleRowClick = async (request) => {
    setSelectedRequest(request);
    setImages(request.images || []); // Load existing images
    setIsModalOpen(true);
    // Fetch item details for each item in the request
    request.items_list.forEach(async (item) => {
      // Access the document in the 'items' collection based on the item_id
      const itemDocRef = doc(firestore, "items", item.item_id);
      const itemDoc = await getDoc(itemDocRef);

      if (itemDoc.exists()) {
        const itemData = itemDoc.data(); // Access the document data

        // Assuming item details are stored inside the document under a field like 'details'
        // You can adjust this field name based on your data structure
        const itemDetailsNested = itemData.details || itemData; // Access nested details if applicable

        setItemDetails((prevDetails) => ({
          ...prevDetails,
          [item.item_id]: itemDetailsNested, // Store nested details in state
        }));
      }
    });
    if (request.plan_id) {
      const planDocRef = doc(firestore, "plans", request.plan_id);
      const planDoc = await getDoc(planDocRef);

      if (planDoc.exists()) {
        setPlanDetails(planDoc.data()); // Store plan data in state
      } else {
        setPlanDetails(null); // Clear if no plan data is found
      }
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setImages([]); // Clear images on modal close
    setPlanDetails(null);
  };
  const acceptRequest = async (e) => {
    try {
      setLoading(true);
      var uid = e.created_by;
      var price = e.payment;
      var reqId = e.id;
      var paymentMethodId = e.paymentMethodId;
      console.log("payment__", {
        uid: uid,
        price: price,
        name: "adrfr",
        requestId: reqId,
        customerId: e.customerId,
      });
      const response = await fetch(
        "https://us-central1-kiip-51772.cloudfunctions.net/subscribe/v2/subscribe",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            uid: uid,
            price: price,
            name: "adrfr",
            requestId: reqId,
            customerId: e.customerId,
            paymentMethodId: paymentMethodId,
          }).toString(),
        }
      );
      const data = await response.json(); // or await response.text(), based on your expected response format
      console.log("payment__", data);
      await updateDoc(doc(firestore, "request", e.id), {
        status: "accepted",
      });
      e.status = "accepted";
      setRequests((prevItems) =>
        prevItems.map((item) =>
          item.id === e.id ? { ...item, status: "accepted" } : item
        )
      );
      setUploading(false);
      setLoading(false);
      alert("Request accepted");
    } catch (error) {
      setUploading(false);
      alert(error);
    }
    // alert(JSON.stringify(e));
  };

  const rejectRequest = async (e) => {
    if (!rejectReason) {
      alert("Please provide a reason for rejection.");
      return;
    }
    const requestCollection = collection(firestore, "request");
    await updateDoc(doc(firestore, "request", selectedRequestForRejection.id), {
      status: "rejected",
      reject_reason: rejectReason,
    });
    e.status = "rejected";
    setRequests((prevItems) =>
      prevItems.map((item) =>
        item.id === selectedRequestForRejection.id
          ? { ...item, status: "Rejected", reject_reason: rejectReason }
          : item
      )
    );
    alert("Request rejected");
    setIsRejectModalOpen(false); // Close the modal
    setRejectReason(""); // Clear the reason input
  };
  // Handle multiple image upload
  const handleImageUpload = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    setUploading(true);
    const uploadedImages = [];
    for (let file of files) {
      const storageRef = ref(
        storage,
        `request_images/${selectedRequest.id}/${file.name}`
      );
      const uploadTask = await uploadBytesResumable(storageRef, file);
      const downloadURL = await getDownloadURL(uploadTask.ref);
      uploadedImages.push(downloadURL);
    }
    // Update Firestore with new images
    const updatedImages = [...images, ...uploadedImages];
    await updateDoc(doc(firestore, "request", selectedRequest.id), {
      images: updatedImages,
    });
    setImages(updatedImages); // Update state with new images
    setUploading(false);
  };
  // Handle image deletion
  const handleDeleteImage = async (imageUrl) => {
    const fileRef = ref(storage, imageUrl);
    // Delete the image from storage
    await deleteObject(fileRef);
    // Remove the image URL from Firestore
    const updatedImages = images.filter((img) => img !== imageUrl);
    await updateDoc(doc(firestore, "request", selectedRequest.id), {
      images: updatedImages,
    });
    setImages(updatedImages); // Update state after deletion
  };
  if (loading) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400">
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>
        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>
        {/* Loading Text with Soft Animation */}
        <p className="relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider">
          Loading...
        </p>
      </div>
    );
  }

  return (
    <div className="xl:px-[151px] sm:px-8 px-3 pb-20 mt-4 mb-10 font-Nunito">
      <div className="rounded-2xl bg-white h-auto sm:p-7 p-3 mb-8">
        <div className="overflow-x-auto overflow-y-auto w-full">
          <div>
            <Calendar
              selectable
              onSelectSlot={(e) => {
                console.log("slot", e.start);
                setDate(e.start);
              }}
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 350 }}
              defaultView="month"
              views={["month", "week"]}
            />
          </div>
        </div>

        <div className="overflow-x-auto overflow-y-auto w-full">
          {/* Reject Reason Modal */}
          {isRejectModalOpen && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-4">Reject Request</h2>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  placeholder="Enter the reason for rejection"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                />
                <div className="mt-4 flex justify-end">
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                    onClick={rejectRequest}
                  >
                    Reject
                  </button>
                  <button
                    className="bg-gray-300 px-4 py-2 rounded"
                    onClick={() => setIsRejectModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {date != null && (
            <div
              onClick={() => {
                setDate(null);
              }}
            >
              <h1 className="text-xl text-gray-500 font-bold mb-4 mt-10">
                Borrar filtro de fecha
              </h1>
            </div>
          )}

          <table className="w-full border border-gray-300 mt-6">
            <thead className="bg-gray-200 text-md font-bold">
              <tr className="text-center">
                <th className="border-b-2 py-2 px-10 ">ID</th>
                <th className="border-b-2 py-2 px-10 ">Imágenes</th>
                <th className="border-b-2 py-2 px-10 ">Ubicación</th>
                <th className="border-b-2 py-2 px-10 ">Fecha-Hora</th>
                <th className="border-b-2 py-2 px-10 ">Tamaño</th>
                <th className="border-b-2 py-2 px-10 ">Monto</th>
                <th className="border-b-2 py-2 px-10 ">Estado</th>
                <th className="border-b-2 py-2 px-10 "></th>
                <th className="border-b-2 py-2 px-10 "></th>
              </tr>
            </thead>
            <tbody className="text-center">
              {requests
                .filter((e) => {
                  var pickDate = new Date(e.scheduleDateTime.toMillis());
                  // console.log("moement", pickDate, date);

                  /* if (moment(pickDate).isAfter(date) ) {
                    return true;
                  } */
                  var dateCHecked =
                    date == null ||
                    (pickDate.getDate() === date.getDate() &&
                      pickDate.getMonth() === date.getMonth() &&
                      pickDate.getFullYear() === date.getFullYear());

                  if (dateCHecked) {
                    if (props.showPending) {
                      if (
                        e.status == null ||
                        e.status == "Pending" ||
                        e.status == "pending"
                      )
                        return true;
                      else return false;
                    }
                    return true;
                  }
                  return false;
                })
                .map((request) => (
                  <tr
                    key={request.id}
                    className="border-b cursor-pointer text-md"
                    onClick={() => handleRowClick(request)}
                  >
                    <td className="py-3">{request.id}</td>
                    <td className="py-3">
                      {request.images && request.images.length > 0 ? (
                        <img
                          src={request.images[0]}
                          alt="request-img"
                          className="w-16 h-16 object-cover rounded-md mx-auto"
                        />
                      ) : (
                        "No Image"
                      )}
                    </td>
                    <td className="py-3">{request.pickup_location}</td>
                    <td className="py-3">{request.pick_up_date_time}</td>
                    <td className="py-3">{request.size}m³</td>
                    <td className="py-3">{request.payment}MXN</td>
                    <td className="py-3">{request.status || "Pending"}</td>
                    {!request["status"] && (
                      <td
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent parent click event
                          acceptRequest(request);
                        }}
                        className="py-3 text-green-500 font-semibold"
                      >
                        Accept
                      </td>
                    )}
                    {!request["status"] && (
                      <td
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent parent click event
                          setSelectedRequestForRejection(request);
                          //rejectRequest(request);
                          setIsRejectModalOpen(true);
                        }}
                        className="py-3 text-red-600 font-semibold"
                      >
                        Reject
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/*Modal*/}
      {isModalOpen && selectedRequest && (
        <div className="fixed inset-0 p-2 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-opacity-80 flex items-center justify-center z-50">
          <div className="bg-white md:p-10 p-5 rounded-3xl shadow-2xl max-w-4xl w-full relative transform transition-transform duration-500 hover:scale-105 overflow-y-auto max-h-screen">
            {/*Close Button and Title Container*/}
            <div className="sticky top-0 bg-white z-10 p-5 rounded-t-3xl shadow-sm">
              <button
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out text-3xl"
                onClick={closeModal}
              >
                &times;
              </button>

              <h2 className="font-bold text-3xl text-center text-gray-800 mb-8 tracking-wider">
                Detalles de la Solicitud
              </h2>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
              <div className="text-gray-700">
                <p className="text-lg">
                  <strong>ID:</strong> {selectedRequest.id}
                </p>
                <p className="text-lg">
                  <strong>Pick-up Location:</strong>{" "}
                  {selectedRequest.pickup_location}
                </p>
                <p className="text-lg">
                  <strong>Pickup date time:</strong>{" "}
                  {selectedRequest.pick_up_date_time}
                </p>
              </div>
              <div className="text-gray-700">
                <p className="text-lg">
                  <strong>Size:</strong> {selectedRequest.size}m³
                </p>

                <p className="text-lg">
                  <strong>Payment:</strong> {selectedRequest.payment}MXN
                </p>
                <p className="text-lg">
                  <strong>Status:</strong> {selectedRequest.status || "Pendding"}
                </p>

                {planDetails ? (
                  <div className="border-2 p-3 bg-gray-200">
                    <p className="text-lg">
                      <strong>Plans:</strong>
                    </p>
                    <h1 className="font-bold">{planDetails.name}</h1>
                    <p className="font-normal">
                      {planDetails.minimum}.0m x {planDetails.minimum}.0m (up to{" "}
                      {planDetails.upto}m³)
                    </p>
                    <p className="font-bold">{planDetails.price} MXN</p>
                  </div>
                ) : (
                  <div className="mt-8">
                    <p className="text-gray-800 font-semibold text-xl">
                      Items List:
                    </p>
                    {selectedRequest.items_list &&
                      Array.isArray(selectedRequest.items_list) ? (
                      <ul className="list-disc list-inside text-gray-600 pl-6 mt-4 space-y-3">
                        {selectedRequest.items_list.map((item, idx) => (
                          <div key={idx} className="text-lg">
                            {itemDetails[item.item_id] && (
                              <div className="text-gray-600">
                                <span>
                                  <strong>Item:</strong>{" "}
                                  {itemDetails[item.item_id].es_name}{" "}
                                  <strong className="ml-3">Quantity:</strong>{" "}
                                  {item.quantity}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                      </ul>
                    ) : (
                      <p>No items available</p>
                    )}
                  </div>
                )}
              </div>
              {/* Box Details */}
              <div className="mt-4">
                <h3 className="text-lg font-semibold">Box Details:</h3>
                {selectedRequest.box_details &&
                  Array.isArray(selectedRequest.box_details) &&
                  selectedRequest.box_details.length > 0 ? (
                  selectedRequest.box_details.map((box, index) => (
                    <div key={index} className="mb-2">
                      <p>
                        <span className="">Box Name:</span> {box.boxName}
                        {"   "}
                        <span className="ml-4">Code:</span> {box.boxCode}
                      </p>
                      <p>
                        <strong>Text:</strong> {box.boxContent}
                      </p>
                      {/* Add other box details fields if necessary */}
                    </div>
                  ))
                ) : (
                  <p>No box details available.</p>
                )}
              </div>
            </div>

            {/*Image upload button*/}
            <div className="mt-8 flex items-center">
              <label
                htmlFor="image-upload"
                className="inline-block cursor-pointer bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out"
              >
                {uploading ? "Uploading..." : "Upload Images"}
              </label>
              <input
                id="image-upload"
                type="file"
                multiple
                onChange={handleImageUpload}
                className="hidden"
                disabled={uploading}
              />
            </div>

            {/*Display uploaded images*/}
            {images.length > 0 && (
              <div className="mt-10 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
                {images.map((imageUrl, index) => (
                  <div key={index} className="relative group">
                    <img
                      src={imageUrl}
                      alt={`uploaded ${index}`}
                      className="w-full h-48 object-cover rounded-xl shadow-lg transform transition duration-300 ease-in-out hover:scale-110"
                    />
                    <button
                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 text-lg opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out shadow-md"
                      onClick={() => handleDeleteImage(imageUrl)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ReturnsTable;

import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Homeogo from "../../assets/icons/logo.png";
import { app } from "../../firebase";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { getAuth } from "firebase/auth";
const firestore = getFirestore(app);
const auth = getAuth(app)

function Priceestimated() {
  const location = useLocation();
  const selectedItems =
    location.state?.selectedItems || location.state?.plan || {};
  const userData = location.state?.userData || location.state?.user || {};
  const totalVolumeUsed = location.state?.totalVolumeUsed || 0;
  let { textareaValue, boxDetails } = location.state || {};
  const { randomNumbersList = [], createdAt } = location.state || {};
  const volume = parseFloat((totalVolumeUsed).toFixed(2));
  console.log(selectedItems.price);
  const number = selectedItems.price;
  const planName = selectedItems.name;
  const upto = selectedItems.upto;
  const minimum = selectedItems.minimum;
  let result;

  console.log(boxDetails);

  if (totalVolumeUsed > 0 && totalVolumeUsed <= 1.8) {
    result = parseFloat((349.00).toFixed(2));
  } else if (totalVolumeUsed > 1.8 && totalVolumeUsed <= 3.6) {
    result = parseFloat((698.00).toFixed(2));
  }
  else if (totalVolumeUsed > 3.6 && totalVolumeUsed <= 5.4) {
    result = parseFloat((1047.00).toFixed(2));
  }
  else if (totalVolumeUsed > 5.4 && totalVolumeUsed <= 7.2) {
    result = parseFloat((1396.00).toFixed(2));
  }
  else if (totalVolumeUsed > 7.2 && totalVolumeUsed <= 9) {
    result = parseFloat((1745.00).toFixed(2));
  }
  else if (totalVolumeUsed > 9 && totalVolumeUsed <= 10.8) {
    result = parseFloat((2094.00).toFixed(2));
  }
  else if (totalVolumeUsed > 10.8 && totalVolumeUsed <= 12.6) {
    result = parseFloat((2443.00).toFixed(2));
  }
  else if (totalVolumeUsed > 12.6 && totalVolumeUsed <= 14.4) {
    result = parseFloat((2792.00).toFixed(2));
  }
  else if (totalVolumeUsed > 14.4 && totalVolumeUsed <= 16.2) {
    result = parseFloat((3141.00).toFixed(2));
  }
  else if (totalVolumeUsed > 16.2 && totalVolumeUsed <= 18) {
    result = parseFloat((3490.00).toFixed(2));
  }

  console.log(userData);
  console.log(totalVolumeUsed);
  console.log(textareaValue);
  console.log(randomNumbersList);

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [refference, setRefference] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [additionalInstruction, setAdditionalInstruction] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filldata, setFilldata] = useState(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  // console.log(userData.email);
  const itemsToSave = Object.keys(selectedItems)
    .filter(
      (itemId) =>
        selectedItems[itemId].quantity !== undefined &&
        selectedItems[itemId].quantity !== null
    )
    .map((itemId) => ({
      item_id: itemId,
      quantity: selectedItems[itemId].quantity,

    }));

  const dataToSend = itemsToSave.length > 0 ? null : selectedItems.id;
  const userId = userData.email;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCibRfOSCUJyxdoOdEYI7mqQ5pB9VW6dhc', // Ensure your key is valid and has the right permissions
    libraries: ["places"],
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        setLoading(true);
        const usersCollection = collection(firestore, "Users");
        const q = query(usersCollection, where("email", "==", userId));
        console.log(q);

        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            setFilldata(userDoc.data());
          } else {
            setFilldata(null);
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const user = auth.currentUser;
    setIsLoggedIn(user)
  }, [auth])
  console.log(filldata);

  useEffect(() => {
    if (filldata) {
      setName(filldata.name || "");
      setLastName(filldata.lastName || "");
      setEmail(filldata.email || "");
      setPhoneNumber(filldata.phone || "");
      setAddress(filldata.address || "");
      setRefference(filldata.refference || "");
      setDate(filldata.date || "");
      setTime(filldata.time || "");
    }
  }, [filldata]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleAddressChange = (event) => {
    const input = event.target.value;
    setAddress(input);

    // Initialize Autocomplete Service
    const service = new window.google.maps.places.AutocompleteService();

    // Get suggestions
    if (input) {
      service.getPlacePredictions({ input }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setAutocompleteResults(predictions);
        } else {
          setAutocompleteResults([]);
        }
      });
    } else {
      setAutocompleteResults([]);
    }
  };

  const handleSelectAddress = (place) => {
    setAddress(place.description);
    setAutocompleteResults([]);
  };

  const handlePlaceSelect = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('address-input'));
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setAddress(place.formatted_address);
        setMap({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please enter name ");
      return;
    } else if (!lastName) {
      toast.error("Please enter Lastname ");
      return;
    } else if (!email) {
      toast.error("Please enter email ");
      return;
    } else if (!phoneNumber) {
      toast.error("Please enter your Phone Number");
      return;
    } else if (!refference) {
      toast.error("Please enter refference");
      return;
    } else if (!address) {
      toast.error("Please enter your address");
      return;
    } else if (!date) {
      toast.error("Please add date");
      return;
    } else if (!time) {
      toast.error("Please add time");
      return;
    }

    navigate("/finalestimated", {
      state: {
        selectedItems,
        address,
        additionalInstruction,
        date,
        time,
        randomNumbersList,
        createdAt,
        textareaValue,
        userData,
        totalVolumeUsed,
        boxDetails,
      },
    });
  };
  // Get today's date in 'YYYY-MM-DD' format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(today.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
  };
  if (loadError) {
    return <p>Error loading Google Maps: {loadError.message}</p>;
  }
  if (loading) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400">
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>

        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>

        {/* Loading Text with Soft Animation */}
        <p className="relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider">
          Loading...
        </p>
      </div>
    );
  }
  if (error) return <p>Error: {error}</p>;
  if (!isLoaded) return <div>Loading Maps...</div>;
  return (
    <div className="p-1">
      <ToastContainer />
      <div className="p-2 flex flex-row justify-between border-b-2">
        <div className="flex flex-row gap-2 justify-items-center">
          <div className="mt-3">
            <img src={Homeogo} width={36} height={36} className="" alt="" />
          </div>

          <div className="flex flex-row gap-2">
            <p className="text-5xl">|</p>
            <h2 translate='no' className=" text-3xl mt-2">Kiip</h2>
          </div>
        </div>
        {!isLoggedIn && (

          <div className="flex flex-row gap-3 text-white font-semibold text-xl justify-items-center">
            <Link to="/login">
              <button className="bg-[#3ccad1] rounded-xl  hidden sm:block w-[155px] font-aeroport font-normal h-[52px]">
                Iniciar sesión
              </button>
            </Link>
          </div>
        )}

        <nav className=" sm:hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  onClick={toggleMenu}
                >
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Open main menu</span>
                  {/* Hamburger menu icon */}
                  <svg
                    className={`block h-6 w-6 ${isMenuOpen ? "hidden" : "block"
                      }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                  {/* Close menu icon */}
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div
            className={`sm:hidden ${isMenuOpen ? "block" : "hidden"}`}
            id="mobile-menu"
          >
            <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
              <Link to="/login">
                {" "}
                <button
                  className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
                  aria-current="page"
                >
                  Iniciar sesión
                </button>
              </Link>
            </div>
          </div>
        </nav>
      </div>

      <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
      <div className="items-center mx-auto sm:w-[700px]  ">
        <div className="flex sm:flex-row flex-col gap-4 justify-center items-center">
          <div className=" justify-between flex sm:w-[315px] rounded-lg w-[250px] sm:h-[110px] border-2 mt-3 items-center px-[17px]">
            <div className="text-center mx-auto">
              {itemsToSave.length > 0 ? (
                <>
                  <h1 className="font-semibold text-[#21A3AA]">Colección inicial</h1>
                  <h1 className="font-semibold">Volumen seleccionado.</h1>
                  <p className="font-normal">{`${volume}m³` || "Mini"}</p>
                </>
              ) : (
                <>
                  <h1 className="font-semibold text-[#21A3AA]">Colección inicial</h1>
                  <p className="font-bold">{planName}</p>

                  <p className="font-semibold">{minimum}.0m x {minimum}.0m (up to{" "}
                    {upto}m³)</p>

                </>
              )}
            </div>
          </div>
          <div className=" justify-between flex sm:w-[315px] bg-[#21A3AA] rounded-lg w-[250px] sm:h-[110px] border-2 mt-3 items-center px-[17px]">
            <div className="text-center text-white mx-auto">
              <h1 className="font-semibold  text-4xl mt-1">{result || number}</h1>
              <h1 className="font-extralight text-xs">MXN/Mes</h1>
              <p className="font-semibold text-md">por mes.</p>
              <p className="font-extralight text-xs">
                Máxima flexibilidad, sin compromiso de permanencia.
              </p>
            </div>
          </div>
        </div>
        <div className="font-aeroport p-7">
          <h1 className="font-semibold text-xl">Ver disponibilidad</h1>
          <p className="font-normal  mt-2">
            Introduce tus datos para verificar la disponibilidad de fechas y
            horarios de colección en tu área, te enviaremos los detalles por
            correo electrónico
          </p>
        </div>

        <div className="p-[25px] py-[29px] mb-7">
          <form>
            <div className="space-y-8">
              <div className=" border-gray-900/10 pb-12">
                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                  <div className="sm:col-span-2">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Nombres
                    </label>
                    <div className="mt-2">
                      <input
                        value={name}
                        readOnly
                        onChange={(e) => setName(e.target.value)}
                        required
                        type="text"
                        className="block w-full rounded-md p-1 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Apellidos
                    </label>
                    <div className="mt-2">
                      <input
                        value={lastName}
                        readOnly
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        type="text"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Correo electrónico
                    </label>
                    <div className="mt-2">
                      <input
                        value={email}
                        readOnly
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        type="email"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Número
                    </label>
                    <div className="mt-2">
                      <input
                        value={phoneNumber}
                        readOnly
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        type="number"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Dirección
                    </label>
                    <div className="mt-2">
                      <input
                        id="address-input"
                        type="text"
                        placeholder="Enter address"
                        value={address}
                        onChange={handleAddressChange}
                        onFocus={handlePlaceSelect}
                        required
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Referencia
                    </label>
                    <div className="mt-2">
                      <input
                        value={refference}
                        onChange={(e) => setRefference(e.target.value)}
                        required
                        type="text"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Fecha
                    </label>
                    <div className="mt-2">
                      <input
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                        type="date"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        min={getTodayDate()}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Hora
                    </label>
                    <div className="mt-2">
                      <input
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        required
                        type="time"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                      Instrucción adicional
                    </label>
                    <div className="mt-2">
                      <textarea
                        value={additionalInstruction}
                        onChange={(e) => setAdditionalInstruction(e.target.value)}
                        required
                        type="text"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 flex items-center justify-center gap-x-6">
              <Link to={"/finalestimated"}>
                {" "}
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="text-sm font-semibold font-aeroport leading-6 w-[289px] h-[52px] rounded-2xl bg-[#3ccad1] text-white"
                >
                  Agendar
                </button>
              </Link>
            </div>
          </form>
        </div>
        {/* Map displaying the pinned location
        <GoogleMap
        onLoad={(mapInstance) => setMap(mapInstance)}
        zoom={10}
        center={{ lat: -34.397, lng: 150.644 }} // Set default center
        mapContainerStyle={{ height: "400px", width: "100%" }}
      >
        {map && <Marker position={map} />}
      </GoogleMap> */}
      </div>
    </div>
  );
}

export default Priceestimated;

import React, { useState } from "react";
import ReturnsTable from "./ReturnsTable";
import Dashnavbar from "./Dashnavbar";
import ReturnsNav from "./ReturnsNav";

function ReturnsDashboard() {
    const [showPending, setShowPending] = useState(false);

    return (
        <div className="bg-gray-100 min-h-screen">
            <Dashnavbar />
            <ReturnsNav
                onClick={(value) => {
                    setShowPending(value);
                }}
            />
            <ReturnsTable showPending={showPending} />
        </div>
    );
}

export default ReturnsDashboard;

import React, { useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { Link } from 'react-router-dom'
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';


function Navbar() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='px-4 py-3 flex flex-row justify-between border-b-2 border-[#D9D9D9]'>
      <div className='px-padding flex flex-row gap-2'>
        <div className='mt-3'>
          <img src={Homeogo} width={36} height={36}
            className='' alt='' />


        </div>

        <div className='flex flex-row gap-2'>
          <p className='text-5xl '>|</p>
          <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
        </div>
      </div>

      <div className='flex flex-row sm:gap-3     font-aeroport text-xl justify-items-center'>
        <div className='md:flex flex-row mt-2 gap-3 hidden font-aeroport font-semibold '>
          <Link to="estimatedvalue"> <button className='rounded-xl hidden sm:block font-aeroport font-normal text-[#3D3C3C] w-auto px-2 h-10'>Elige tu plan</button></Link>
          <Link to={"addedqoute"}> <button className='rounded-xl hidden sm:block font-aeroport font-normal text-[#3D3C3C] w-auto px-2 h-10'> Seleccionar elementos</button></Link>
        </div>
        <Link to="/login">
          <button className='bg-[#21A3AA] rounded-xl hidden sm:block font-aeroport font-normal text-white sm:w-[155px] w-[95px] h-[48px]'>Iniciar sesión</button>
        </Link>

        <nav className=" sm:hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  onClick={toggleMenu}
                >
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Open main menu</span>
                  {/* Hamburger menu icon */}
                  <svg
                    className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                  {/* Close menu icon */}
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
            <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
              <Link to="login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Log In</button>
              </Link>  <Link to="show"> <button className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Choose your plan</button>
              </Link><button className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Quote your space</button>

            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
import React, { useState, useEffect } from 'react'
import Homelogo from "../../assets/icons/logo.png"
import { useNavigate, useLocation } from 'react-router-dom';
import { useLoadScript } from "@react-google-maps/api";
import { Timestamp } from "firebase/firestore";
import { collection, getDocs, query, where, setDoc, doc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import { db } from '../../firebase';

function ReturnRequest() {
    const auth = getAuth();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCibRfOSCUJyxdoOdEYI7mqQ5pB9VW6dhc', // Ensure your key is valid and has the right permissions
        libraries: ["places"],
    });
    const [address, setAddress] = useState("");
    const navigate = useNavigate();
    const [date, setDate] = useState("");
    const [items, setItems] = useState("");
    const [autocompleteResults, setAutocompleteResults] = useState([]);
    const [map, setMap] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState("");
    const handleAddressChange = (event) => {
        const input = event.target.value;
        setAddress(input);

        // Initialize Autocomplete Service
        const service = new window.google.maps.places.AutocompleteService();

        // Get suggestions
        if (input) {
            service.getPlacePredictions({ input }, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setAutocompleteResults(predictions);
                } else {
                    setAutocompleteResults([]);
                }
            });
        } else {
            setAutocompleteResults([]);
        }
    };

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const times = [
        "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM",
        "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM",
        "05:00 PM", "06:00 PM"
    ];

    const handlePlaceSelect = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('address-input'));
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                setAddress(place.formatted_address);
                setMap({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
            }
        });
    };

    const location = useLocation();
    const { data } = location.state || {};

    useEffect(() => {

        const fetchData = async () => {
            try {
                let results = [];
                for (const item of data.items_list) {
                    const q = query(
                        collection(db, "items"),
                        where("id", "==", item.item_id)
                    );
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach(doc => {
                        results.push(doc.data());
                    });
                }
                setItems(results)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const [selectedItems, setSelectedItems] = useState([]);

    const toggleSelect = (id) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    };

    const confirm = async () => {
        if (!selectedItems.length > 0) {
            toast.error("Please select at least one item.");
            return;
        }
        if (!date) {
            toast.error("Please select the date.");
            return;
        }
        if (!selectedTime) {
            toast.error("Please select the time.");
            return;
        }
        if (!additionalInfo) {
            toast.error("Please add the Additional Information.");
            return;
        }
        if (!address) {
            toast.error("Please add the Delivery Address.");
            return;
        }



        navigate("/finalestimated", {
            state: {
                selectedItems: selectedItems,
                address: address,
                additionalInstruction: additionalInfo,
                date: date,
                time: selectedTime,
                createdAt: Timestamp.fromDate(new Date()),
                totalVolumeUsed: 0,
                boxDetails: "",
                isReturn: true,
                data: data
            },
        });
    };



    return (
        <div>
            <ToastContainer />
            <div className='sm:p-4 p-2   border-b-2'>
                <div className='flex flex-row gap-2 justify-items-center'>
                    <div className='mt-3'>
                        <img src={Homelogo} width={36} height={36}
                            className='' alt='' />
                    </div>
                    <div className='flex flex-row gap-2'>
                        <p className='text-5xl'>|</p>
                        <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
                    </div>
                </div>
            </div>
            <div
                onClick={() => navigate(-1)}
                className=" flex flex-row mt-3 sm:mx-32 mx-12"
            >
                <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
                <p className="mt-2">Regresar</p>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full max-w-md bg-white p-6 rounded-2xl">
                    <h2 className="text-xl font-bold text-center">Schedule Return</h2>

                    <label className="block font-semibold mt-4">Items:</label>

                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <li key={index} className="flex flex-1 justify-between items-center space-x-3 hover:cursor-pointer border rounded-md m-1 p-1" onClick={() => toggleSelect(item.id)}>
                                <span>{item.es_name}</span>
                                <input
                                    type="checkbox"
                                    checked={selectedItems.includes(item.id)}
                                    className="peer hidden"
                                />
                                <div className="h-5 w-5 border-2 border-gray-400 rounded-md flex items-center justify-center peer-checked:bg-[#3ccad1] peer-checked:border-[#3ccad1]">
                                    {selectedItems.includes(item.id) && (
                                        <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                    )}
                                </div>

                            </li>

                        ))
                    ) : (
                        <p>Loading...</p>
                    )}
                    <label className="block font-semibold mt-4">Select Date:</label>
                    <input
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                        type="date"
                        className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        min={getTodayDate()}
                    />
                    <div className={`grid grid-cols-3 gap-2 mt-2 ${!date ? "hidden" : "show pt-3"}`}>
                        {times.map((time) => (
                            <button
                                key={time}
                                className={`py-2 px-4 rounded-full border ${selectedTime === time ? "bg-[#3ccad1] text-white" : "bg-white"}`}
                                onClick={() => setSelectedTime(time)}
                            >
                                {time}
                            </button>
                        ))}
                    </div>
                    <label className="block font-semibold mt-4"

                    >Additional Information:</label>
                    <textarea placeholder="Additional Information" className="w-full p-2 border rounded-md mt-1 h-20"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        type="text"
                        required
                    />

                    <label className="block font-semibold mt-4">Delivery Address:</label>
                    <input
                        value={address}
                        id="address-input"
                        type="text"
                        placeholder={isLoaded ? "Enter address" : "Loading..."}
                        onChange={handleAddressChange}
                        onFocus={handlePlaceSelect}
                        required
                        className={`w-full p-2 border rounded-md mt-1`}
                        disabled={!isLoaded}
                    />
                    <button className="bg-[#3ccad1] text-white py-2 px-4 rounded-md mt-6 w-full"
                        onClick={confirm}
                    >Confirm</button>
                </div>
            </div>
        </div>

    )
}

export default ReturnRequest;

import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Ensure correct Firebase import
import Homeogo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";

function Returns() {
    const navigate = useNavigate();
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRequests = async () => {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                setError("You need to be logged in to view returns.");
                setLoading(false);
                return;
            }

            try {
                const q = query(collection(db, "return_requests"), where("createdBy", "==", user.uid));
                const querySnapshot = await getDocs(q);
                const fetchedRequests = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setRequests(fetchedRequests);
                console.log(fetchedRequests)
            } catch (err) {
                console.error("Error fetching requests:", err);
                setError("Failed to load returns.");
            } finally {
                setLoading(false);
            }
        };

        fetchRequests();
    }, []);

    return (
        <div>
            <div className="sm:p-4 p-2 border-b-2">
                <div className="flex flex-row gap-2 justify-items-center">
                    <div className="mt-3">
                        <img src={Homeogo} width={36} height={36} alt="Logo" />
                    </div>
                    <div className="flex flex-row gap-2">
                        <p className="text-5xl">|</p>
                        <h2 translate="no" className="text-3xl mt-2">Kiip</h2>
                    </div>
                </div>
            </div>
            <div onClick={() => navigate(-1)} className="flex flex-row mt-3 sm:mx-32 mx-12 cursor-pointer">
                <button className="text-xl mt-1 w-6.5 h-6.5">{"<"}</button>
                <p className="mt-2">Regresar</p>
            </div>
            <div className="flex flex-col items-center mt-20">
                <h2 className="text-4xl font-bold font-aeroport">Returns</h2>
            </div>
            <div className="flex flex-col items-center mt-6">
                {loading && <p className="text-lg">Loading...</p>}
                {error && <p className="text-red-500">{error}</p>}
            </div>
            <div className="sm:mx-32 mx-12 mt-6">
                {requests.length === 0 && !loading && !error && (
                    <p className="text-center text-gray-500">No return requests found.</p>
                )}
                {requests.map((request) => (
                    <div key={request.id} className="p-4 mb-4 border rounded-lg shadow-sm bg-slate-100">
                        <p><strong>Request ID:</strong> {request.id}</p>
                        <p><strong>No. of items:</strong> {request.itemsList.length}</p>
                        <p><strong>Scheduled:</strong> {new Date(request.scheduleDateTime.seconds * 1000).toLocaleString()}</p>
                        <p><strong>Additional Info:</strong> {request.additionalInfo}</p>
                        <p><strong>Delivery Address:</strong> {request.deliveryAddress}</p>
                        <p><strong>Status:</strong> {request.status}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Returns;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import calender from "../../assets/icons/calendar.png";
import Homeogo from "../../assets/icons/logo.png";
import { db } from '../../firebase';
import { generateId } from "../../functions/utils"
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
  getDoc
} from "firebase/firestore";
import { app } from "../../firebase";
import { deleteCard, getCards, saveCard, useStripeCustomer, addPaymentList } from "../nanopages/Stripecall";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-modal";
import { getAuth } from "firebase/auth";
const ss = require("stripe")(
  "sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK"
);
const firestore = getFirestore(app);
const auth = getAuth(app)

const stripePromise = loadStripe(
  "pk_test_51Poq3nRrhvPQKbL0xDAfF1lSSgY8Gx8ZSkTXLH2zcdg1nV05GWNiPlwtE41tdugWIiA5JvLi6RkQRKsp4PribGzq00CNhRWlN2"
);
Modal.setAppElement("#root");
function Finalestimated() {
  const location = useLocation();
  const { selectedItems } = location.state || {};
  const { data } = location.state || {};
  const { address, date, time } = location.state || {};
  const { totalVolumeUsed } = location.state || {};
  let { textareaValue, additionalInstruction } = location.state || {};
  let { boxDetails } = location.state || {};
  let { isReturn } = location.state || {};
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  boxDetails = boxDetails || null;
  textareaValue = textareaValue || "";
  additionalInstruction = additionalInstruction || null;

  useEffect(() => {
    const user = auth.currentUser;
    setIsLoggedIn(!!user)
  }, [auth])
  const itemsToSave = Object.keys(selectedItems)
    .filter(
      (itemId) =>
        selectedItems[itemId].quantity !== undefined &&
        selectedItems[itemId].quantity !== null
    )
    .map((itemId) => ({
      item_id: itemId,
      quantity: selectedItems[itemId].quantity,

    }));
  const volume = parseFloat((totalVolumeUsed).toFixed(2));

  const volumePricing = [
    { limit: 1.8, price: 349.00 },
    { limit: 3.6, price: 698.00 },
    { limit: 5.4, price: 1047.00 },
    { limit: 7.2, price: 1396.00 },
    { limit: 9, price: 1745.00 },
    { limit: 10.8, price: 2094.00 },
    { limit: 12.6, price: 2443.00 },
    { limit: 14.4, price: 2792.00 },
    { limit: 16.2, price: 3141.00 },
    { limit: 18, price: 3490.00 }
  ];
  const result = volumePricing.find(v => totalVolumeUsed <= v.limit)?.price || 550;

  const size = parseFloat(totalVolumeUsed.toFixed(2)) || selectedItems.upto;
  const navigate = useNavigate();
  const { randomNumbersList = [], createdAt } = location.state || {};
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectOption, setSelectOption] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [SaveModalopen, setSaveModalopen] = useState(false);
  const [cards, setCards] = useState([]);
  const [defaultCardId, setDefaultCardId] = useState(null);
  const [language, setLanguage] = useState('es');
  const [user, setUser] = useState(null);

  const formattedDate = createdAt
    ? new Date(createdAt).toLocaleString()
    : new Date().toLocaleString();
  const dateObject = new Date(formattedDate);
  const timestamp = Timestamp.fromDate(dateObject);
  const pickupDateTime = new Date(`${date}T${time}`);
  const pickupTimestamp = Timestamp.fromDate(pickupDateTime);
  const boxCodes = randomNumbersList.map(String);

  useEffect(() => {
    const fetchUser = async () => {
      const userDoc = await getDoc(doc(db, "Users", auth.currentUser.uid));
      if (userDoc) {
        setUser(userDoc.data());
      }
    }
    fetchUser();
    console.log(user)
  }, [])

  const openModal = () => {
    setIsModalOpen(true);
    fetchCards();
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const optionModal = () => {
    if (!acceptTerms) {
      toast.error("You must accept the terms and conditions to proceed.");
      return;
    }
    setSelectOption(true);
    fetchCards();
  };
  const closeOptionModal = () => {
    setSelectOption(false);
  };
  const addNewcardModal = () => {

    setAddNewCard(true);

  };
  const closeNewcardModal = () => {
    setAddNewCard(false);
  };


  const saveModalopen = () => {
    setSaveModalopen(false);
  };
  const saveModalclose = () => {
    saveModalclose(true);
    fetchCards();
  };
  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage; // Detect browser language
    setLanguage(userLanguage.startsWith('en') ? 'en' : 'es'); // If English, set to 'en', otherwise 'es'
  }, []);

  const handleSubmit = async (e, stripe, elements) => {
    e.preventDefault();
    try {
      setLoading("customerId", true);
      var customerId = user.customerId;
      if (customerId == null || customerId == undefined) {
        const customers = await ss.customers.search({
          query: "email:'" + user.email + "'",
          limit: 1,
        });
        if (customers.data.length != 0) {
          customerId = customers.data[0].id;
        }
      }
      if (customerId == null || customerId == undefined) {
        const customer = await ss.customers.create({
          name: `${user.name} ${user.lastName}`,
          email: `${user.email}`,
        });
        customerId = customer.id;
      }

      setLoading(false);
      console.log("customerId", customerId);

      if (!stripe || !elements) {
        console.log("Not yet");
        return;
      }
      const cardElement = elements.getElement(CardElement);
      console.log(cardElement);
      var paymentResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      console.log(paymentResponse);

      console.log("paymentResponse", paymentResponse.error);
      if (paymentResponse.error) {
        alert(paymentResponse.error.message);
        setLoading(false);
        return;
      }
      const { paymentMethod } = paymentResponse;
      console.log(`Method>>>${paymentMethod}`);
      const payment = await ss.paymentMethods.attach(paymentMethod.id, {
        customer: customerId,
      });
      const choice = await openConfirmModal();
      if (choice === "yes") {
        console.log("User chose to save the payment method.");
        await addPaymentList(customerId, paymentMethod.id);
      } else {
        console.log("User chose not to save the payment method.");
      }

      var reqId = generateId(15);

      setLoading(false);


      if (isReturn) {
        createReturnRequest();
        return;
      }
      const itemsToSave = Object.keys(selectedItems)
        .filter(
          (itemId) =>
            selectedItems[itemId].quantity !== undefined &&
            selectedItems[itemId].quantity !== null
        )
        .map((itemId) => ({
          item_id: itemId,
          quantity: selectedItems[itemId].quantity,

        }));

      const dataToSend = itemsToSave.length > 0 ? null : selectedItems.id;

      console.log("dataToSend", dataToSend);

      try {
        setLoading(true);

        console.log("adding");
        const docRef = doc(collection(firestore, "request"), reqId);
        const userRef = doc(collection(firestore, "Users"), user.uid);

        // Define your document data
        const documentData = {
          box_codes: boxCodes,
          created_by: user.uid,
          id: reqId,
          images: null,
          items_list: itemsToSave,
          created_at: Timestamp.fromDate(new Date()),
          pickup_date_time: pickupTimestamp,
          pickup_location: address,
          additional_Instruction: additionalInstruction,
          pickup_location_latlng: null,
          plan_id: dataToSend,
          customer_id: customerId,
          size: size,
          status: "Pending",
          box_details: boxDetails,
          what_inside_box: textareaValue,
          // payment: result,
        };
        console.log("userdata__", documentData);

        // Set the document data
        await setDoc(docRef, {
          box_codes: boxCodes,
          created_by: user.uid,
          id: reqId,
          images: null,
          items_list: itemsToSave,
          created_at: Timestamp.fromDate(new Date()),
          pickup_date_time: pickupTimestamp,
          pickup_location: address,
          pickup_location_latlng: null,
          plan_id: dataToSend,
          customer_id: customerId,
          size: parseFloat(totalVolumeUsed.toFixed(2)) || selectedItems.upto,
          what_inside_box: textareaValue,
          box_details: boxDetails,
          additional_Instruction: additionalInstruction,
          customerId: customerId,
          payment: result,
          paymentMethodId: paymentMethod.id,
        });
        await updateDoc(userRef, {
          customerId: customerId,
        });


        setLoading(false);

        navigate("/submition", { state: { user } });
      } catch (e) {
        setLoading(false);
        alert(` ${e}`);

        console.error("Error adding document: ", e);
      }
    } catch (error) {
      console.log("the error is ".error);
      setLoading(false);

      toast.error(`payment method is not added ${error}`);
    }

    return (
      <div>
        <button onClick={handleSubmit}>Make Payment</button>
      </div>
    );


  };


  const createReturnRequest = async () => {
    const id = generateId(15);
    const datetime = new Date(`${date} ${time}`);
    const scheduleDateTime = Timestamp.fromDate(datetime);
    const requestData = {
      id: id,
      createdBy: auth.currentUser.uid,
      requestId: data.id,
      additionalInfo: additionalInstruction,
      deliveryAddress: address,
      scheduleDateTime: scheduleDateTime,
      status: "pending",
      itemsList: selectedItems.map((item) => ({
        item_id: item,
        quantity: 1,
      }))
    };


    try {
      await setDoc(doc(db, "return_requests", id), requestData);
      toast.success("Request submitted successfully!");
      await delay(2000)
      navigate(-1);
    } catch (error) {
      console.error("Error submitting request:", error);
      toast.error("Error submitting request. Please try again.");
    }
  }
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const fetchCards = async () => {
    setLoading(true);
    console.log("user:", user);
    const cardData = await getCards(user.customerId);
    setCards(cardData);
    setLoading(false);
  };

  const handleSave = async () => {
    if (!defaultCardId) {
      toast.error("Please select a default card before saving.");
      return;
    }
    if (isReturn) {
      createReturnRequest();
      return;
    }
    var reqId = generateId(15);
    setLoading(false);
    console.log("userData.uid", user.uid);
    const itemsToSave = Object.keys(selectedItems)
      .filter(
        (itemId) =>
          selectedItems[itemId].quantity !== undefined &&
          selectedItems[itemId].quantity !== null
      )
      .map((itemId) => ({
        item_id: itemId,
        quantity: selectedItems[itemId].quantity,

      }));

    const dataToSend = itemsToSave.length > 0 ? null : selectedItems.id;

    console.log("dataToSend", dataToSend);

    try {
      setLoading(true);
      console.log("adding");
      const docRef = doc(collection(firestore, "request"), reqId);
      console.log(reqId);

      const docData = {
        box_codes: boxCodes,
        created_by: user.uid,
        id: reqId,
        images: null,
        items_list: itemsToSave,
        created_at: Timestamp.fromDate(new Date()),
        pickup_date_time: pickupTimestamp,
        pickup_location: address,
        pickup_location_latlng: null,
        plan_id: dataToSend,
        customer_id: user.customerId,
        size: parseFloat(totalVolumeUsed.toFixed(2)) || selectedItems.upto,
        what_inside_box: textareaValue,
        box_details: boxDetails,
        additional_Instruction: additionalInstruction,
        customerId: user.customerId,
        payment: result,
        paymentMethodId: defaultCardId,
      };

      // Set the document data
      await setDoc(docRef, docData);



      setLoading(false);

      navigate("/submition", { state: { user } });
    } catch (e) {
      setLoading(false);
      alert(` ${e}`);

      console.error("Error adding document: ", e);
    }
    console.log('Saved default card:', defaultCardId); // Log for debugging
  };

  const openConfirmModal = () => {
    return new Promise((resolve) => {
      // Open modal here and pass in callbacks
      setConfirmModal(true);

      // Set up event listeners to resolve based on user action
      const handleYes = () => {
        setConfirmModal(false);
        resolve("yes");
      };
      const handleNo = () => {
        setConfirmModal(false);
        resolve("no");
      };

      // // Here you would add event listeners for modal "Yes" and "No" buttons
      window.handleYes = handleYes;
      window.handleNo = handleNo;
    });
  };



  const handleCheckboxChange = (id) => {
    setDefaultCardId(id);
    const updatedCards = cards.map(card => ({
      ...card,
      isDefault: card.id === id,
    }));
    setCards(updatedCards);
  };

  console.log(defaultCardId);


  return (
    <div className="bg-gray-100 min-h-screen  lg:h-[850px]">
      <ToastContainer />
      <div className="p-4 flex flex-row justify-between border-b-2">
        <div className="flex flex-row gap-2 justify-items-center">
          <div className="mt-3">
            <img src={Homeogo} width={36} height={36} className="" alt="" />
          </div>

          <div className="flex flex-row gap-2">
            <p className="text-5xl">|</p>
            <h2 translate='no' className=" text-3xl mt-2">Kiip</h2>
          </div>
        </div>
        {!isLoggedIn && (

          <div className="flex flex-row gap-3 text-white font-semibold text-xl justify-items-center">
            <Link to="/login">
              <button className="bg-[#3ccad1] rounded-xl  hidden sm:block w-[155px] font-aeroport font-normal h-[52px]">
                Iniciar sesión
              </button>
            </Link>
          </div>
        )}

        <nav className=" sm:hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  onClick={toggleMenu}
                >
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Open main menu</span>
                  {/* Hamburger menu icon */}
                  <svg
                    className={`block h-6 w-6 ${isMenuOpen ? "hidden" : "block"
                      }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                  {/* Close menu icon */}
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div
            className={`sm:hidden ${isMenuOpen ? "block" : "hidden"}`}
            id="mobile-menu"
          >
            <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
              <Link to="/login">
                {" "}
                <button
                  className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
                  aria-current="page"
                >
                  Iniciar sesión
                </button>
              </Link>{" "}
            </div>
          </div>
        </nav>
      </div>

      <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
      <div className="items-center mx-auto sm:w-[744px]  ">
        <div className="flex md:flex-row flex-col gap-7 justify-center items-center">
          <div className=" justify-between flex sm:w-[315px] rounded-lg w-[250px] sm:h-[110px] border-2 mt-3 items-center px-[17px]">
            <div className="text-center mx-auto">
              {itemsToSave.length > 0 ? (
                <>
                  <h1 className="font-semibold text-[#21A3AA]">Colección inicial</h1>
                  <h1 className="font-semibold">Volumen seleccionado.</h1>
                  <p className="font-normal">{`${volume}m³` || "Mini"}</p>
                </>
              ) : (
                <>
                  <h1 className="font-semibold text-[#21A3AA]">Colección inicial</h1>
                  <p className="font-bold">{selectedItems.name}</p>

                  <p className="font-semibold">{selectedItems.minimum}.0m x {selectedItems.minimum}.0m (up to{" "}
                    {selectedItems.upto}m³)</p>

                </>
              )}
            </div>
          </div>
          <div className=" justify-between flex sm:w-[363px] bg-[#21A3AA] rounded-lg w-[300px] md:h-[110px] h-auto border-2 mt-3 items-center px-[17px]">
            <div className="text-center text-white mx-auto">
              <h1 className="font-semibold  text-4xl mt-2">{result}</h1>
              <h1 className="font-extralight text-xs">MXN/Mex</h1>
              <p className="font-semibold text-md">por mes.</p>
              <p className="font-extralight text-xs mb-2">
                Máxima flexibilidad, sin compromiso de permanencia.
              </p>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col items-center  gap-7">
          <div className=" mt-3">
            {/**1st */}
            <div className="mt-2">
              <h1 className="font-aeroport font-semibold text-2xl">
                Fecha de recolección
              </h1>

              <div className="flex p-2 flex-row  items-center bg-white h-[44px] sm:w-[363px] w-[300px] gap-2">
                <img src={calender} alt="" className="w-[13.5] h-[15px]" />
                <h1 className="font-aeroport font-extralight">
                  {date}
                </h1>
              </div>
            </div>
            {/**2nd */}
            <div className="mt-7">
              <h1 className="font-aeroport font-semibold text-2xl">
                Identidad
              </h1>
              <p>Escribe esto en tu caja para identificar</p>
              <div className="h-96  overflow-y-auto">

                {randomNumbersList.length > 0 ? (
                  randomNumbersList.flatMap((numbers, boxIndex) =>
                    numbers.map((number, index) => {
                      const totalBoxes = randomNumbersList.reduce((acc, curr) => acc + curr.length, 0); // Flatten array to count total boxes
                      const currentBoxIndex = randomNumbersList.slice(0, boxIndex).reduce((acc, curr) => acc + curr.length, 0) + index + 1; // Calculate box index
                      return (
                        <div
                          key={`${boxIndex}-${index}`} // Unique key for each number
                          className="flex p-2 flex-row items-center mt-4 justify-center bg-white h-[82px] sm:w-[363px] w-[300px] gap-2"
                        >
                          <h1 className="font-aeroport font-bold text-3xl">
                            Box {currentBoxIndex}: {number}
                          </h1>
                        </div>
                      );
                    })
                    //   (
                    //   <div
                    //     key={`${boxIndex}-${index}`} // Unique key for each number
                    //     className="flex p-2 flex-row items-center mt-4 justify-center bg-white h-[82px] sm:w-[363px] w-[300px] gap-2"
                    //   >
                    //     <h1 className="font-aeroport font-bold text-3xl">
                    //     Box {boxIndex * numbers.length + index + 1}: {number}
                    //     </h1>
                    //   </div>
                    // ))
                  )
                ) : (
                  <div className="flex p-2 flex-row items-center mt-4 justify-center bg-white h-[82px] sm:w-[363px] w-[300px] gap-2">
                    <h1 className="font-aeroport font-bold text-3xl">
                      No box item added
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>

            <div></div>
            <div className="mt-7 mb-8">
              <h1 className="font-aeroport font-semibold text-2xl">
                Resumen de pago
              </h1>
              <div className="sm:w-[363px] w-[300px] mt-4 sm:h-[240px] h-auto bg-white">
                <div className=" p-4 border-b-2 space-y-3">
                  <div className="flex flex-row justify-between font-aeroport ">
                    <div>
                      <h1 className="font-semibold text-md ">{totalVolumeUsed ? `Price for volume:${size}` : "Plan"}</h1>
                    </div>
                    <div>
                      <h1 className="font-semibold text-md">{result}</h1>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between font-aeroport ">
                    <div>
                      <h1 className="font-semibold text-md ">

                      </h1>
                    </div>
                    <div>
                      <h1 className="font-semibold text-md">MXN</h1>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between font-aeroport ">
                    <div>
                      <h1 className="font-semibold text-md">
                        Entrega y recoger
                        {/* {language === 'en'? 'Delivery and Pickup'
: 'Entrega y recogida'} */}
                      </h1>
                    </div>
                    <div>
                      <h1 className="font-semibold text-md">0</h1>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between font-aeroport ">
                    <div>
                      <h1 className="font-semibold text-md ">

                      </h1>
                    </div>
                    <div>
                      <h1 className="font-semibold text-md">MXN</h1>
                    </div>
                  </div>

                </div>
                <div className="flex flex-row justify-between font-aeroport mt-2 mb-5 p-4">
                  <h1 className="font-semibold text-lg">Total</h1>
                  <div>
                    <h1 className="font-semibold text-md">{result}</h1>
                    <h1 className="font-semibold text-md">MXN</h1>
                  </div>
                </div>
              </div>

              {/* <Elements stripe={stripePromise}>
                  <CheckoutForm
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </Elements> */}

              <button className="bg-[#3ccad1] rounded-xl mt-4 w-full h-[52px] text-white font-semibold" onClick={optionModal}>Payment Options</button>

              {selectOption && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-8 rounded-lg shadow-2xl w-96 text-center">


                    <h1 className="text-xl font-semibold text-gray-800 mb-4 text-center">
                      Select a Payment Option
                    </h1>

                    <div className="space-y-2">
                      {cards.length !== 0 && (

                        <button className="flex items-center justify-between w-full px-4  text-blue-500  font-medium rounded-lg">
                          <span onClick={openModal}>Payment with existing cards</span>

                        </button>
                      )}
                      <button className="flex items-center justify-between w-full px-4 py-3 text-blue-500  font-medium rounded-lg">
                        <span onClick={addNewcardModal}>Payment with new card</span>

                      </button>
                    </div>
                    <button
                      onClick={closeOptionModal}
                      className="absolute  mr-32 text-gray-500 hover:text-gray-800 text-center "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              {isModalOpen && (


                <div className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 z-50"
                >
                  <div className="bg-white p-6  rounded-lg shadow-lg w-96">
                    <div className="flex flex-row  justify-between">
                      <div>

                        <h2 className="text-lg font-semibold mb-4">Select Card</h2>
                      </div>
                      <div className="mr-8">

                        <button
                          onClick={closeModal}
                          className="absolute  mr-32 text-gray-500 hover:text-gray-800"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div>
                      {loading ? ( // Conditional rendering based on loading state
                        <p>Loading cards...</p>
                      ) : (

                        <div className='mt-8 space-y-2'>
                          {cards.map((card, index) => ( // Mapping over the cards array
                            <div key={index} className='bg-white rounded-md flex p-3 justify-between w-60'>
                              <h1>{`${card.brandName} **** **** **** ${card.last4digits}`}</h1> {/* Adjust according to your card data structure */}
                              <input
                                type="checkbox"
                                checked={card.isDefault} // Set checkbox checked based on isDefault value
                                onChange={() => handleCheckboxChange(card.id)}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      <button className="bg-[#3ccad1] rounded-xl mt-4 w-full h-[52px] text-white font-semibold" onClick={handleSave}>Pay</button>
                    </div>
                  </div>
                </div>
              )}

              {addNewCard && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-8 rounded-lg shadow-2xl w-96 text-center">
                    <div>
                      Add Card
                    </div>
                    <div>
                      <Elements stripe={stripePromise}>
                        <CheckoutForm
                          handleSubmit={handleSubmit}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </Elements>
                    </div>
                    <div className="mt-5">
                      <button onClick={closeNewcardModal}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}

              {confirmModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                    <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                      Do you want to save the card?
                    </h2>
                    <div className="flex justify-between">
                      <button
                        onClick={window.handleYes}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                      >
                        YES
                      </button>
                      <button
                        onClick={window.handleNo}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                      >
                        NO
                      </button>
                    </div>
                  </div>
                </div>
              )}


            </div>
            {/* Checkbox for terms and conditions */}
            <div className=" items-center my-4">
              <input
                type="checkbox"
                id="terms"
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
                className="mt-2 mr-5"
              />
              <label htmlFor="terms" className="text-sm">  We're glad you're here. Your data is safe and encrypted. By continuing you agree to our <label style={{ color: '#33AEB5' }}>Terms & Conditions</label> and <label style={{ color: '#33AEB5' }}>Privacy Policy</label>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckoutForm({ handleSubmit, loading }) {
  const stripe = useStripe();
  const elements = useElements();
  const isStripeLoaded = stripe && elements;
  return (
    <form onSubmit={(e) => handleSubmit(e, stripe, elements)}>

      <div className="mt-5">
        <CardElement />
        <button
          type="submit"
          disabled={!isStripeLoaded || loading}
          className="bg-[#3ccad1] rounded-xl mt-4 w-full h-[52px] text-white font-semibold"
        >
          {loading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-3 text-white text-center"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              Processing...
            </>
          ) : (
            "Pay"
          )}
        </button>
      </div>
    </form>
  );
}

export default Finalestimated;

import React, { useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import inventory from "../../assets/icons/inventory.png"
import calculator from "../../assets/icons/calculator.png"
import delivery from "../../assets/icons/delivery.png"
import plan from "../../assets/icons/plan.png"
import profile from "../../assets/icons/profile.png"
import { getAuth, signOut } from 'firebase/auth'
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { app } from '../../firebase'

function Control() {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [requestCount, setRequestCount] = useState(0);
  const { user } = location.state || {};
  console.log(user);
  const firestore = getFirestore(app);
  console.log(userData);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.email) {
        const usersCollection = collection(firestore, 'Users');
        const q = query(usersCollection, where('email', '==', user.email));

        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            setUserData(userDoc.data());
          } else {
            setUserData(null);
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError('User email not found.');
      }
    };
    const fetchRequestCount = async () => {
      if (user?.uid) {
        try {
          const requestCollection = collection(firestore, 'request');
          const q = query(requestCollection, where('created_by', '==', user.uid));
          const querySnapshot = await getDocs(q);
          setRequestCount(querySnapshot.size); // Set the number of requests
        } catch (err) {
          console.error('Error fetching request count:', err);
        }
      }
    };

    fetchUserData();
    fetchRequestCount(); // Fetch the request count

    fetchUserData();
  }, [user, firestore]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Handle logout
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User logged out');
        navigate('/'); // Navigate to login page after logout
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  };


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleNavigate = (path) => {
    navigate(path, { state: { user, userData } });
  };
  const handleProfileClick = () => {
    navigate('/profileinfo', { state: { userEmail: user?.email, userData } });
  };
  const handleRequest = () => {
    navigate('/request', { state: { user } })
  }

  if (loading) {
    return (
      <div className='relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400'>
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>

        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>

        {/* Loading Text with Soft Animation */}
        <p className='relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider'>
          Loading...
        </p>
      </div>
    );
  }


  if (error) return <p>Error: {error}</p>;
  return (

    <div className='p-1'>
      <div className='p-4 flex flex-row justify-between border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
          <div className='mt-3'>
            <img src={Homeogo} width={36} height={36}
              className='' alt='' />


          </div>

          <div className='flex flex-row gap-2'>
            <p className='text-5xl'>|</p>
            <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
          </div>
        </div>

        <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
          <div onClick={handleProfileClick} className='w-[52px] h-[52px] hidden sm:block bg-[#009DB5] rounded-full content-center'>

            <img src={profile} alt="" className='w-[18px] h-[18px]  mx-auto' />
          </div>

        </div>

        <nav className=" sm:hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  onClick={toggleMenu}
                >
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Open main menu</span>
                  {/* Hamburger menu icon */}
                  <svg
                    className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                  {/* Close menu icon */}
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
            <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
              <button onClick={handleLogout} className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Log out</button>
            </div>
          </div>
        </nav>
      </div>
      <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
      <div className=' mt-3 sm:mx-32 mx-12'>

        <h1 className='mt-6 font-bold text-lg'>¡Bienvenido {userData?.name || 'Usuario'}!</h1>
        <p>Aquí puedes acceder a tu historial<br /> digital de movimientos</p>
      </div>
      <div className='items-center mx-auto sm:w-auto lg:w-[1200px] mt-7  '>

        <div className='flex lg:flex-row flex-col gap-4 justify-center items-center flex-wrap'>

          <div onClick={handleRequest} className='flex rounded-lg w-[300px] sm:h-[168px] border-2 mt-3 items-center px-[4px]'>
            <div className='flex flex-1 flex-row items-center p-4 justify-between'>
              <div>
                <h1 className='font-normal text-xs mt-1'><span className='text-xl font-bold' >{requestCount}</span> artículos almacenados</h1>
                <h1 className='text-sm font-bold'>Mi inventario</h1>
              </div>
              <div>
                <img src={inventory} alt="" className='w-[122px] h-[86px]' />
              </div>
            </div>
          </div>

          <div onClick={() => handleNavigate("/addedqoute")} className=' flex    rounded-lg w-[300px] sm:h-[168px] border-2 mt-3 items-center px-[4px]'>
            <div className='flex flex-1 flex-row items-center p-4 justify-between'>
              <div className='items-center'>
                <h1 className=' text-xl font-bold'>Calculadora</h1>
              </div>
              <div>
                <img src={calculator} alt="" className='w-[71px] h-[98px]' />
              </div>
            </div>
          </div>
          <Link to={"/estimatedvalue"}>
            <div className='flex rounded-lg w-[300px] sm:h-[168px] border-2 mt-3 items-center px-[4px]'>
              <div className='flex flex-1 flex-row items-center p-4 justify-between'>
                <div className='items-center'>
                  <h1 className=' text-xl font-bold'>Planes</h1>
                </div>
                <div>
                  <img src={plan} alt="" className='w-[84px] h-[95px]' />
                </div>
              </div>
            </div></Link>

          <Link to={"/returns"}>
            <div className='flex rounded-lg w-[300px] sm:h-[168px] border-2 mt-3 items-center px-[4px]'>
              <div className='flex flex-1 flex-row items-center p-4 justify-between'>
                <div className='items-center'>
                  <h1 className=' text-xl font-bold'>Returns</h1>
                </div>
                <div>
                  <img src={delivery} alt="" className='w-[84px] h-[95px]' />
                </div>
              </div>
            </div></Link>
        </div>
      </div>
    </div>

  )
}

export default Control

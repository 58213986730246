import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ai from "../../../assets/icons/ailogo.png";
import downarrow from "../../../assets/icons/downarrow.png";
import Homeogo from "../../../assets/icons/logo.png";
import { app } from "../../../firebase";

function Dashnavbar() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const { user } = location.state || {};
  const firestore = getFirestore(app);
  const navigate = useNavigate();
  console.log(user);

  useEffect(() => {
    if (user && user.uid) {
      // Fetch the user data based on the document ID (user.uid)
      const fetchUserData = async () => {
        try {
          const userRef = doc(firestore, "Users", user.uid); // Reference to the user document
          const userSnap = await getDoc(userRef); // Fetch the document

          if (userSnap.exists()) {
            setUserData(userSnap.data()); // Set the user data if the document exists
            console.log("User data:", userSnap.data());
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }
  }, [user, firestore]); // UseEffect depends on user and db

  const handleProfileClick = () => {
    navigate("/adminupdate", { state: { userData } });
  };
  const handleUserListing = () => {
    navigate("/userlisting", { state: { user } });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <div className="p-3 flex flex-row justify-between border-b-2">
        <div className="flex flex-row gap-2 justify-items-center">
          <div className="mt-3">
            <img src={Homeogo} width={36} height={36} className="" alt="" />
          </div>

          <div className="flex flex-row gap-2">
            <p className="text-5xl ">|</p>
            <h2 translate='no' className=" text-3xl mt-2">Kiip</h2>
          </div>
        </div>
        {userData && userData.role != "admin" && (
          <div className=" font-aeroport hidden md:block">
            <button className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl">
              <Link to={"/dashboardmenu"}>Panel de Control</Link>
            </button>
            <button
              onClick={handleUserListing}
              className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl"
            >
              Listado de Usuarios
            </button>
            <button className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl">
              <Link to={"/dashboardrequest"}>Solicitud</Link>
            </button>
            <button className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl">
              <Link to={"/warehouse"}>Almacén</Link>
            </button>
          </div>
        )}
        {userData && userData.role == "admin" && (
          <div className="flex space-x-2">
            <div
              className=" font-aeroport hidden md:block"
              onClick={() => {
                navigate("/dashboardmenu");
              }}
            >
              <button className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl border">
                <Link to={"/dashboardmenu"}>Panel</Link>
              </button>
            </div>
            <div
              className=" font-aeroport hidden md:block"
              onClick={() => {
                navigate("/returnsdashboard");
              }}
            >
              <button className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl border">
                <Link to={"/returnsdashboard"}>Panel de devoluciones</Link>
              </button>
            </div>
            <div
              className=" font-aeroport hidden md:block"
              onClick={() => {
                signOut(getAuth(app));
                navigate("/");
              }}
            >
              <button className="w-[150px] h-[50px] hover:bg-[#3ccad1] rounded-2xl border">
                <Link to={"/dashboardmenu"}>cerrar sesión</Link>
              </button>
            </div>
          </div>
        )}
        <div className="flex flex-row gap-3    font-aeroport text-xl justify-items-center">
          <div
            onClick={handleProfileClick}
            className="md:flex flex-row items-center justify-between gap-2 hidden font-aeroport font-normal "
          >
            <div className="flex flex-row  gap-1">
              <img src={ai} alt="" className="w-[36px] h-[36px]" />
              <h1 className="font-aeroport font-normal">
                {userData ? userData.name : "Loading..."}
              </h1>
            </div>
            <div>
              <img src={downarrow} alt="" className="w-[22px] h-[22px]" />
            </div>
          </div>

          <nav className=" md:hidden px-8">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <button
                    type="button"
                    id="mobile-menu-button"
                    className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    aria-controls="mobile-menu"
                    aria-expanded={isMenuOpen ? "true" : "false"}
                    onClick={toggleMenu}
                  >
                    <span className="absolute -inset-0.5"></span>
                    <span className="sr-only">Open main menu</span>
                    {/* Hamburger menu icon */}
                    <svg
                      className={`block h-6 w-6 ${isMenuOpen ? "hidden" : "block"
                        }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                    {/* Close menu icon */}
                    <svg
                      className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* Mobile menu */}
            <div
              className={`md:hidden ${isMenuOpen ? "block" : "hidden"}`}
              id="mobile-menu"
            >
              <div className="space-y-1 items-center sm:px-2 pt-2 pb-3 bg-gray-300">
                <button
                  onClick={handleProfileClick}
                  href="#"
                  className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
                  aria-current="page"
                >
                  Admin
                </button>
                <button
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-white bg-gray-900 hover:text-white"
                >
                  <Link to={"/dashboardmenu"}>Panel de Control</Link>
                </button>
                <button
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-white bg-gray-900 hover:text-white"
                >
                  <Link to={"/userlisting"}>Listado de Usuarios</Link>
                </button>
                <button
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-white bg-gray-900 hover:text-white"
                >
                  <Link to={"/dashboardrequest"}>Solicitud</Link>
                </button>
                <button
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-white bg-gray-900 hover:text-white"
                >
                  <Link to={"/warehouse"}>Almacén</Link>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Dashnavbar;

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Homeogo from "../../assets/icons/logo.png";
import { app } from "../../firebase";
import { useDebouncSearch } from "../shared/searchDebounce";

function Form() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [email, setEmail] = useDebouncSearch("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [emailExists, setEmailExists] = useState(true);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  console.log(email);

  const location = useLocation();
  const selectedItems =
    location.state?.selectedItems || location.state?.plan || {};
  const { totalVolumeUsed } = location.state || {};
  //const { plan } = location.state || {};
  console.log(selectedItems);
  console.log(email);

  console.log(totalVolumeUsed > 18);
  console.log(userData);
  console.log(selectedItems);

  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the user is logged in
    const user = auth.currentUser;
    setIsLoggedIn(!!user);

    if (user) {
      // If the user is logged in, fetch their data
      const fetchUserData = async () => {
        const userDoc = await getDocs(query(collection(firestore, "Users"), where("uid", "==", user.uid)));
        if (!userDoc.empty) {
          const data = userDoc.docs[0].data();
          setUserData(data);
          setEmail(data.email);
          setName(data.name);
          setLastName(data.lastName);
          setPhone(data.phone);
          setPostalCode(data.postalCode);
        }
      };
      fetchUserData();
    }
  }, [auth, firestore]);


  const checkEmailExists = async (email) => {
    console.log("api calling");
    try {
      const q = query(
        collection(firestore, "Users"),
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setEmailExists(true);
        const userDoc = querySnapshot.docs[0];
        setUserData(userDoc.data());
        setName(userDoc.data().name);
        setLastName(userDoc.data().lastName);
        setPhone(userDoc.data().phone);
        setPostalCode(userDoc.data().postalCode);
      } else {
        setEmailExists(false);
        setUserData("");
        setName("");
        setLastName("");
        setPhone("");
        setPostalCode("");
      }
    } catch (error) {
      setEmailExists(false);
      setUserData("");
      setName("");
      setLastName("");
      setPhone("");
      setPostalCode("");
      console.error("Error checking email:", error);
      setEmailExists(false);
    }
  };

  useEffect(() => {
    if (email) {
      console.log("calling");
      checkEmailExists(email);
    }
  }, [email]);

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please enter name");
      return;
    } else if (!lastName) {
      toast.error("Please enter Lastname");
      return;
    } else if (!email) {
      toast.error("Please enter email");
      return;
    } else if (!phone) {
      toast.error("Please enter your Phone Number");
      return;
    } else if (!postalCode) {
      toast.error("Please enter your postalCode Number");
      return;
    }
    const itemsToSave = Object.keys(selectedItems)
      .filter(
        (itemId) =>
          selectedItems[itemId].quantity !== undefined &&
          selectedItems[itemId].quantity !== null
      )
      .map((itemId) => ({
        item_id: itemId,
        quantity: selectedItems[itemId].quantity,

      }));

    const dataToSend = itemsToSave.length > 0 ? null : selectedItems.id;
    if (!emailExists) {
      if (!password) {
        toast.error("Please enter password");
        return;
      }
      try {
        setLoading(true);
        let user;
        // Create user with Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = userCredential.user;
        console.log(user);

        // Store user data in Firestore
        setUserData({
          name,
          lastName,
          email,
          phone,
          postalCode,
          uid: user.uid,
        });
        await setDoc(doc(firestore, "Users", user.uid), {
          name,
          lastName,
          email,
          phone,
          postalCode,
          uid: user.uid,
        });
        setLoading(false);
        alert("User registered successfully!");
        if (totalVolumeUsed > 18) {
          console.log(totalVolumeUsed > 18);

          if (window.confirm("Your totalVolumeUsed is greater than 18. Admin will contact you. Click OK to continue.")) {
            // Store overflow data in Firestore
            await addDoc(collection(firestore, "overflow"), {
              totalVolumeUsed,
              email,
              createdAt: new Date(),
              created_by: user.uid,
              item_list: itemsToSave,
            });
            navigate("/"); // Navigate to home screen or any other suitable page
          }
        } else {

          const containsSpecialItem = Object.values(selectedItems).some(
            (item) => item.category === "dxa9HzgOxsWcqnrhv77s"
          );
          //console.log(containsSpecialItem);

          if (containsSpecialItem) {
            navigate("/estimatetextarea", {
              state: {
                user: {
                  uid: user.uid,
                  name: name,
                  email: user.email,
                },
                selectedItems,
                totalVolumeUsed,
              },
            });
          } else {
            navigate("/estimatedprice", {
              state: {
                user: {
                  uid: user.uid,
                  name: name + " " + lastName,
                  email: user.email,
                },
                selectedItems,
                totalVolumeUsed,
              },
            });
          }
          // }
          // Optionally redirect to another page
        }

      } catch (error) {
        setLoading(false)
        console.error("Error signing up: ", error);
        alert("This is not a valid email");
      }
    } else {
      if (totalVolumeUsed > 18) {
        console.log(totalVolumeUsed > 18);

        if (window.confirm("Your totalVolumeUsed is greater than 18. Admin will contact you. Click OK to continue.")) {
          // Store overflow data in Firestore
          await addDoc(collection(firestore, "overflow"), {
            totalVolumeUsed,
            email,
            createdAt: new Date(),
            created_by: userData.uid,
            item_list: itemsToSave,
          });
          navigate("/"); // Navigate to home screen or any other suitable page
        }
      } else {
        const containsSpecialItem = Object.values(selectedItems).some(
          (item) => item.category === "dxa9HzgOxsWcqnrhv77s"
        );

        if (containsSpecialItem) {
          navigate("/estimatetextarea", {
            state: { selectedItems, totalVolumeUsed, userData },
          }); // Replace with your desired path for "bnsasba"
        } else {
          navigate("/estimatedprice", {
            state: { selectedItems, totalVolumeUsed, userData },
          }); // Replace with your desired path for others
        }
        // }
      }
    }
  };


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  if (loading) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400">
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>

        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>

        {/* Loading Text with Soft Animation */}
        <p className="relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider">
          Loading...
        </p>
      </div>
    );
  }
  return (
    <div className="bg-gray-100 min-h-screen lg:w-[850]">
      <ToastContainer />
      <div className="p-4 flex flex-row justify-between border-b-2">
        <div className="flex flex-row gap-2 justify-items-center">
          <div className="mt-3">
            <img src={Homeogo} width={36} height={36} className="" alt="" />
          </div>

          <div className="flex flex-row gap-2">
            <p className="text-5xl">|</p>
            <h2 translate='no' className=" text-3xl mt-2">Kiip</h2>
          </div>
        </div>

        {/* Conditionally render the login button if the user is not logged in */}
        {!isLoggedIn && (
          <div className="flex flex-row gap-3 text-white font-semibold text-xl justify-items-center">
            <Link to="/login">
              <button className="bg-[#3ccad1] rounded-xl hidden sm:block w-[155px] font-aeroport font-normal h-[52px]">
                Iniciar sesión
              </button>
            </Link>
          </div>
        )}
        <nav className=" sm:hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  onClick={toggleMenu}
                >
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Open main menu</span>
                  {/* Hamburger menu icon */}
                  <svg
                    className={`block h-6 w-6 ${isMenuOpen ? "hidden" : "block"
                      }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                  {/* Close menu icon */}
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div
            className={`sm:hidden ${isMenuOpen ? "block" : "hidden"}`}
            id="mobile-menu"
          >
            <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
              <Link to="/login">
                {" "}
                <button
                  className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
                  aria-current="page"
                >
                  Iniciar sesión
                </button>
              </Link>{" "}
            </div>
          </div>
        </nav>
      </div>
      <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-7 h-7">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
      <div className="flex flex-col items-center mt-12  ">
        <div className="px-4">
          <h2 className="text-4xl text-center  font-semibold font-aeroport">
            Déjanos tus datos para que podamos enviarte tu cotización
          </h2>
        </div>
        <div className="flex flex-row gap-10 mx-auto mt-5 ">
          <div className="lg:w-[870px] w-auto lg:h-[473px] bg-white h-auto border-2 rounded-3xl sm:p-6 p-[18px] py-7 mb-7">
            <form>
              <div className="space-y-12">
                <div className=" border-gray-900/10 pb-12">
                  <div className="mt-10 grid grid-cols-1 sm:gap-x-6 gap-x-4 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                        Dirección de correo electrónico
                      </label>
                      <div className="mt-2">
                        <input
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          required
                          className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                        Nombres
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          className="block p-1 w-full bg-gray-200 rounded-md  py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                          // value={
                          //   userData && userData.name ? userData.name : name
                          // }
                          //
                          disabled={userData && userData.name ? "disabled" : ""}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                        Apellidos
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          value={lastName}
                          disabled={
                            userData && userData.lastName ? "disabled" : ""
                          }

                          onChange={(e) => setLastName(e.target.value)}
                          required
                          className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    {!emailExists && (
                      <div className="sm:col-span-3">
                        <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                          Contraseña
                        </label>
                        <div className="mt-2">
                          <input
                            type="password"
                            className="block p-1 w-full bg-gray-200 rounded-md py-1.5 text-gray-900 shadow-sm ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Contraseña"
                          />
                        </div>
                      </div>
                    )}

                    <div className="sm:col-span-3">
                      <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                        TELÉFONO
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          value={phone}
                          disabled={
                            userData && userData.phone ? "disabled" : ""
                          }

                          onChange={(e) => setPhone(e.target.value)}
                          required
                          className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                        Código Postal
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          disabled={
                            userData && userData.postalCode ? "disabled" : ""
                          }
                          value={postalCode}

                          onChange={(e) => setPostalCode(e.target.value)}
                          required
                          className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 flex items-center justify-center gap-x-6">
                <button
                  onClick={handleSignup}
                  type="submit"
                  className="text-sm  font-semibold font-aeroport leading-6 w-72 h-14 rounded-2xl bg-[#3ccad1] text-white mb-8"
                >
                  VER MI COTIZACIÓN
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
